<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_user()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Dane podwykonawcy</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>

                <v-text-field
                  v-model="user_data.name"
                  label="Nazwa"
                  color="primary"
                ></v-text-field>

                <v-text-field
                  v-model="user_data.email"
                  label="Email"
                  color="primary"
                ></v-text-field>

                <v-text-field
                  v-model="user_data.number"
                  label="Telefon"
                  color="primary"
                ></v-text-field>

                <v-autocomplete
                  v-model="user_data.role_id"
                  :items="role"
                  item-text="name"
                  item-value="id"
                  no-data-text="Brak wyników"
                  label="Rola"
                  :rules="$store.state.rules.not_null"
                ></v-autocomplete>
               
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć podwykonawce?"
      @close="dialog = false;"
      @confirm="delete_user()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista podwykonawców',
        disabled: false,
        to: '/podwykonawcy',
      },
      {
        text: 'Edytuj podwykonawce',
        disabled: true,
        to: '/edit',
      }
    ],

    user_data: {
      id: 1,
      email: 'Rzeszów',
      name: 'Andrzej Maślak z ZOO',
      numebr: '1233',
      role_id: 0,
    },
    
    role: [],
  }),
  methods: {
    edit_user() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("name", this.user_data.name);
      formData.append("email", this.user_data.email);
      formData.append("number", this.user_data.number);
      formData.append("role_id", this.user_data.role_id);

      this.$axios({url: this.$store.state.api +'/customer/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/podwykonawcy');
          this.$store.commit("snackbar", {
            text: "Wprowadzono zmiany",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    delete_user(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/customer/'+this.id, data: {}, method: 'DELETE' })
        .then(resp => {
          this.$router.push('/podwykonawcy');
          this.$store.commit("snackbar", {
            text: "Usunięto użytkownika",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    // Edytowanie linku
    this.breadcrumb[2].to = '/uzytkownik/'+this.id;

    let loader = 2;
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/customer/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.user_data = resp.data;
        console.log(resp.data);
        // this.user_data = resp.data.user_assign;
        this.$store.commit('loader_off');
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })

    this.$axios({url: this.$store.state.api +'/auth/role', data: {}, method: 'GET' })
      .then(resp => {
        this.role = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>